/**
 * BE STUDIO
 * BLIND TIGER
 * Web Application
 *
 * Skincare Single Product Page Logic
 *
 * @author E. Lew & B. Mockute
 * @copyright (C)2022, BE Studio
 * @see www.its-be-studio.com
 * @version 1.x
 */
function getSkincareProduct(id) {
  function getAttributes(attributes) {
    const output = {
      size: null,
      scentNotes: null,
      ingredients: null
    };
    let ingredients = "";
    for(let attribute of attributes) {
      switch(attribute.name) {
        case "Size":
          output.size = sanitize(attribute.value);
          break;
        case "Scent Notes":
          output.scentNotes = sanitize(attribute.value);
          break;
        case "Ingredients List 1":
        case "Ingredients List 2":
        case "Ingredients List 3":
        case "Ingredients List 4":
        case "Ingredients List 5":
          ingredients += sanitize(attribute.value + " ");
          break;
        default:
          break;
      }
    }

    output.ingredients = ingredients;

    return output;
  }

  function replaceZeroes(text) {
    return text.replace(/0/g, "O");
  }

  setTimeout(() => {
    const code = id
    fetch('https://app.ecwid.com/api/v3/80677026/products/' + code + "?token=public_Amf6mCv4hZQC4Zjdc7yaXCqS55ZbyGD4")
    .then((response) => response.json())
    .then((data) => {
      setTimeout(() => {
        const title = document.querySelector(".product-title");
        const price = document.querySelector(".product-price");
        const desc = document.querySelector(".product-description");
        const size = document.querySelector(".product-size");
        const scentNotes = document.querySelector(".product-scent-notes");
        const ingredients = document.querySelector(".product-ingredients");
        const attributes = getAttributes(data.attributes);

        title.innerHTML = sanitize(data.name + ":<br />" + data["subtitleTranslated"]["en"]);
        price.innerHTML = replaceZeroes(sanitize("$ " + data["price"]));
        desc.innerHTML = sanitize(data.description);
        size.innerHTML = replaceZeroes(attributes.size);
        scentNotes.innerHTML = attributes.scentNotes;
        ingredients.innerHTML = attributes.ingredients;
      }, 2000);
    })
    .catch(error => {
      alert("There has been a problem getting the information on this product. Please try again later or contact Blind Tiger. Thank you for your understanding.");
      console.log(error);
    });
  }, 1000);
}
